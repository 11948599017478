import React, { useEffect, useId } from 'react'
import 'tailwindcss/tailwind.css'
interface Props {
  onClose?: () => void,
  open?: boolean,
  showHeader?: boolean,
  class?: string,
  loading?: 'eager' | 'lazy',
  children?: React.ReactNode | any,
  aside: React.ReactNode | any,
}

function Drawer(props: Props) {
  const {
    children,
    aside,
    open,
    showHeader,
    onClose,
    class: _class = '',
    loading = 'lazy',
  } = props
  let lazy = false
  const id = useId()

  useEffect(() => {
    const handler = (e: KeyboardEvent) =>
      (e.key === 'Escape' || e.keyCode === 27) && open && onClose?.()

    addEventListener('keydown', handler)

    return () => {
      removeEventListener('keydown', handler)
    }
  }, [open])

  useEffect(() => {
    lazy = false
  }, [])

  return (
    <div className={`drawer ${_class}`}>
      <input
        id={id}
        checked={open}
        type="checkbox"
        className="drawer-toggle"
        onChange={e => e.currentTarget.checked === false && onClose?.()}
      />

      <div className="drawer-content">
        {children}
      </div>

      <aside
        style={{height: showHeader ? 'calc(100% - 120px)' : ''}}
        className={`drawer-side sm:overflow-hidden z-50 ${showHeader ? 'bottom-0 top-auto start-auto' : 'h-full start-0 overflow-y-visible'}`}
      >
        <label htmlFor={id} className="drawer-overlay" aria-label="drawer overlay" />
        {!lazy && aside}
      </aside>
    </div>
  )
}

export default Drawer
