import 'tailwindcss/tailwind.css'
import Icon from '../DecoIcon/Icon'
import Button from './DecoButton'

export default function MenuButton({ displayMenu, setDisplayMenu }: { displayMenu: boolean, setDisplayMenu: Function }) {

  return (
    <Button
      className="btn btn-ghost min-h-0 h-auto p-2 pt-[13px] pl-0"
      aria-label="open menu"
      onClick={() => {
        setDisplayMenu()
      }}
    >
      {displayMenu
        ? <Icon id="XMark" size={20} strokeWidth={2} />
        : <Icon id="Bars3" size={20} strokeWidth={0.01} />}
    </Button>
  )
}
