import { useState } from 'react'
import 'tailwindcss/tailwind.css'
import { replaceDecoUrl } from '../../../../../utils/replaceDecoUrl'
import Icon from '../../DecoIcon/Icon'
import Button from '../DecoButton'

interface Props {
  loading: boolean
  currency: string
  total: number
  items: any[] | any
}


function CartButtonn({ loading, currency, total, items }: Props) {
  const totalItems = items.reduce((acc, item) => acc + item.quantity, 0)

  return (
    <div className="indicator">
      {totalItems !== 0
        ? (
          <span className="bg-primary text-primary-content rounded-full absolute w-4 h-4 top-0 right-0 text-[10px] leading-4 text-center">
            {totalItems}
          </span>
        )
        : null}
      <Button
        className="btn-circle btn-sm btn-ghost hover:text-primary"
        aria-label="open cart"
        data-deco={false && 'open-cart'}
        loading={loading}
        onClick={() => window.location.href = replaceDecoUrl('/login')}
      >
        <Icon id="ShoppingCart" size={24} strokeWidth={12} />
      </Button>
    </div>
  )
}

export default CartButtonn
