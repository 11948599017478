import 'tailwindcss/tailwind.css'
import { replaceDecoUrl } from '../../../utils/replaceDecoUrl'
import Social, { SocialItem } from '../DecoFooter/Social'
import Icon, { AvailableIcons } from './DecoIcon/Icon'
import { Logo } from './DecoLogo'

export interface ImageObject {
  url: string | any | undefined
  alt: string
}

export interface SiteNavigationElementLeaf {
  /** @description An image of the item. This can be a {@link https://schema.org/URL URL} or a fully described {@link https://schema.org/ImageObject ImageObject}. */
  image?: ImageObject
  /** @description The name of the item. */
  label?: string
  /** @description URL of the item. */
  url?: string
}

export interface SiteNavigationElement extends SiteNavigationElementLeaf {
  children?: Omit<SiteNavigationElement, 'image'>[]
}

export type FastLink = Omit<SiteNavigationElementLeaf, 'image'> & {
  icon?: AvailableIcons;
}

export interface Props {
  /** @description Recomended to max of 2 children trees */
  items?: SiteNavigationElement[]
  fastLinks?: FastLink[]
  socialItems?: any[] | any | SocialItem[]
}

const TEXT = [
  'text-base',
  'text-sm',
  'text-sm',
]

const CHILD_TITLE = [
  '!p-4 !pl-2',
  '!py-4 !px-4 !pl-2 peer-checked:bg-base-300 peer-checked:border-b border-white',
  '!py-4 !px-8 !pl-6 bg-base-300',
]

function MenuItem(
  { item, child = 0 }: { item: SiteNavigationElement; child?: number },
) {
  const hasChildren = !!item.children?.length
  return (
    <li
      className={`${
        hasChildren ? 'collapse collapse-plus rounded-none ' : ''
      } relative items-start`}
    >
      {hasChildren
        ? (
          <>
            <input
              aria-label={item.label + ' input'}
              type="checkbox"
              className="w-1/4 h-full justify-self-end peer"
            />
            <ul className="collapse-content !px-0 divide-y divide-white">
              {item.children?.map((node, index) => (
                <MenuItem key={index} item={node} child={child + 1} />
              ))}
            </ul>
          </>
        )
        : null}
      <div
        className={`${
          CHILD_TITLE[child]
        } collapse-title after:!w-5 after:!h-5 after:!static after:!leading-5 after:!text-center after:!text-2xl flex items-center justify-between`}
      >
        <a className={`${TEXT[child]} flex-grow`} href={replaceDecoUrl(item.url)}>{item.label}</a>
      </div>
    </li>
  )
}

function Menu({ items = [], fastLinks = [], socialItems = [] }: Props) {
  return (
    <div className="flex flex-col h-full">
      <ul className="px-4 flex-grow bg-base-100 flex flex-col divide-y divide-[#D4DBD7]">
        {items?.map(item => <MenuItem item={item} key={item.label}/>)}
      </ul>

      <ul className="px-4 flex flex-col py-2 bg-base-200">
        {fastLinks?.map(link => (
          <li key={link.label}>
            <a
              className="flex items-center gap-2 p-4"
              href={replaceDecoUrl(link.url)}
            >
              {link.icon
                ? <Icon id={link.icon} size={20} strokeWidth={2} />
                : null}
              <span className="text-base font-normal">{link.label}</span>
            </a>
          </li>
        ))}
      </ul>
      <div className="bg-secondary text-secondary-content flex flex-row justify-between items-end flex-wrap pt-5 pb-6 px-4">
        <a
          aria-label="Deco.cx website"
          href="/"
          target="_blank"
          className="flex items-center gap-1 text-sm"
        >
          <Logo color="white" />
        </a>
        <Social content={{ items: socialItems }} vertical={false} />
      </div>
    </div>
  )
}

export default Menu
