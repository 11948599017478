import Head from 'next/head'
import styled from 'styled-components'
import 'tailwindcss/tailwind.css'
import { decoFooterProps, decoHeaderProps } from '../../services/deco/api_defaults'
import { DecoFooterProps, DecoHeaderProps } from '../../services/interfaces/DecoComponentsDtos'
import { IPostMeta } from '../../services/interfaces/WordpressServiceDtos'
import { buildMeta, buildMetaScript } from '../../utils/buildMeta'
import DecoFooter from '../organisms/DecoFooter/Footer'
import DecoHeader from '../organisms/DecoHeader/Header'
import { Footer } from '../organisms/Footer'
import Header from '../organisms/Header'

const Container = styled.div<{ showHeader: boolean }>`
  padding-top: ${props => props.showHeader ? '60px' : ''};
  overflow: hidden;

  @media only screen and (min-width: 1065px) {
    padding-top: ${props => props.showHeader ? '96px' : ''};
  }
`

interface BaseProps {
  title: string
  meta?: IPostMeta[]
  metaScript?: any[]
  children: React.ReactNode,
  language?: string,
  showLeads?: boolean,
  showHeader?: boolean,
  showFooter?: boolean,
  showDecoHeader?: boolean,
  showDecoFooter?: boolean,
  noIndex?: boolean,
  decoHeaderProps: DecoHeaderProps,
  decoFooterProps: DecoFooterProps,
}

export const Base = (props: BaseProps) => {
  const showHeader: boolean = props.showHeader ?? false
  const showFooter: boolean = props.showFooter ?? false
  const noIndex: boolean = props.noIndex ?? false
  const showDecoHeader: boolean = props.showDecoHeader ?? true
  const showDecoFooter: boolean = props.showDecoFooter ?? true

  return (
    <>
      <Head>
        <title>{props.title}</title>
        {noIndex
          ? <>
            <meta name="robots" content="noindex" />
            <meta name="robots" content="nofollow" />
          </>
          : <>
            {props.meta && buildMeta(props.meta)}
            {props.metaScript && buildMetaScript(props.metaScript)}
          </>
        }
        <style>
          {
            `
              html: {
                font-family: 'Lato', sans-serif;
              }
              body: {
                font-family: 'Lato', sans-serif;
              }
              * {
                font-family: 'Lato', sans-serif;
              }
            `
          }
        </style>
      </Head>
      {showDecoHeader && <DecoHeader {...props.decoHeaderProps} />}
      {showHeader && <Header language={props.language} />}
      <Container showHeader={showHeader}>
        {props.children}
      </Container>
      {showDecoFooter && <DecoFooter {...props.decoFooterProps} />}
      {showFooter && <Footer language={props.language} />}
    </>
  )
}