import 'tailwindcss/tailwind.css'
import Icon from '../DecoHeader/DecoIcon/Icon'
export interface PaymentItem {
  label:
    | 'American'
    | 'Diners'
    | 'Elo'
    | 'Mastercard'
    | 'Pix'
    | 'Visa'
    | 'PagaLeve'
}

export default function PaymentMethods(
  { content }: { content?: { title?: string; items?: PaymentItem[] } },
) {
  return (
    <>
      {content && content.items && content.items.length > 0 && (
        <>
          <div className="hidden md:flex flex-col gap-4">
            {content.title && (
              <h3 className="text-sm font-bold">{content.title}</h3>
            )}
            <ul className="flex items-center gap-2 flex-wrap">
              {content.items.map(item => {
                return (
                  <li
                    className="border rounded"
                    title={item.label}
                    key={item.label}
                  >
                    <Icon
                      width={48}
                      height={32}
                      strokeWidth={1}
                      id={item.label}
                    />
                  </li>
                )
              })}
            </ul>
          </div>
          <div className="flex flex-col md:hidden gap-4">
            <div className="collapse collapse-arrow text-sm">
              <input
                aria-label={content.title + ' input'}
                type="checkbox"
                className="min-h-[0]"
              />
              <div className="collapse-title font-bold min-h-[0] !p-0 flex gap-2">
                {content.title && (
                  <h3 className="md:text-lg text-sm" style={{marginTop: '20px'}}>{content.title}</h3>
                )}
              </div>
              <div className="collapse-content">
                <ul className="flex items-center gap-4 flex-wrap pt-2 pl-0">
                  {content.items.map(item => {
                    return (
                      <li
                        className="border"
                        title={item.label}
                        key={item.label}
                      >
                        <Icon
                          width={48}
                          height={32}
                          strokeWidth={1}
                          id={item.label}
                        />
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
