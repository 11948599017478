import { captureException } from '@sentry/nextjs'
import { ReactEventHandler, useState } from 'react'
import 'tailwindcss/tailwind.css'
import LeadStore from '../../stores/LeadStore/LeadStore'
import Icon from '../DecoHeader/DecoIcon/Icon'
import { validateEmail } from './validateEmails'

export interface Form {
  placeholder?: string
  buttonText?: string
  /** @format rich-text */
  helpText?: string
}

export interface Props {
  content: {
    title?: string;
    description?: string;
    form?: Form;
  }
  layout?: {
    tiled?: boolean;
  }
  store: LeadStore
}

function Newsletter({ content, layout = {}, store }: Props) {
  const [loading, setLoading] = useState(false)
  const [sended, setSended] = useState(false)
  const [sendable, setSendable] = useState(false)
  const [inputIsEmpty, setInputIsEmpty] = useState(true)

  const handleSubmit: ReactEventHandler<HTMLFormElement> = async e => { // react event handler?
    e.preventDefault()

    if (!sendable) return

    try {
      setLoading(true)

      const email = (
        e.currentTarget.elements.namedItem('email') as RadioNodeList
      )?.value

      store.addEmail(email)
      await store.sendEmail('pt', false, 'BLOG')
      setSended(store.successSendEmail)
    } catch (error) {
      captureException(error)
    } finally {
      setLoading(false)
    }
  }

  const handleChange: ReactEventHandler<HTMLInputElement> = e => {
    const email = e.currentTarget.value

    setInputIsEmpty(email.length <= 0)
    setSendable(validateEmail(email))
  }

  return (
    <div className="flex lg:flex-nowrap flex-wrap lg:gap-20 gap-6 max-lg:justify-stretch max-lg:items-stretch justify-start">
      <div className="flex flex-col flex-auto lg:gap-4 gap-3 lg:min-w-[400px]">
        {content?.title && (
          <h3 className="text-2xl font-medium">
            {content?.title}
          </h3>
        )}
        {content?.description && (
          <div
            className="lg:max-w-[400px] w-full text-sm"
            dangerouslySetInnerHTML={{ __html: content.description }}
          />
        )}
      </div>

      <div className="flex flex-col gap-4 w-full">
        <form
          className="form-control flex flex-row max-lg:w-full flex-nowrap gap-2"
          onSubmit={handleSubmit}
        >
          <input
            name="email"
            className="flex-auto lg:flex-none lg:max-w-xs lg:w-full w-auto input-bordered input-accent input text-secondary-content bg-secondary placeholder:text-secondary-content disabled:text-secondary-content disabled:bg-secondary"
            placeholder={content?.form?.placeholder || 'Digite seu email'}
            onChange={e => {
              handleChange(e)
            }}
            disabled={sended}
          />

          {!sended && (
            <button
              type="submit"
              className={`btn btn-accent group text-base disabled:bg-white ${loading ? 'loading text-white' : ''
                }`}
              disabled={inputIsEmpty}
            >
              <span className="group-disabled:text-black">
                {content?.form?.buttonText || 'Inscrever'}
              </span>
            </button>
          )}

          {sended && (
            <p className="flex items-center font-bold">
              <Icon width={27} height={28} strokeWidth={1} id={'Check'} />
              Cadastrado!
            </p>
          )}
        </form>
        {content?.form?.helpText && (
          <div
            className="text-sm"
            dangerouslySetInnerHTML={{ __html: content?.form?.helpText }}
          />
        )}
      </div>
    </div>
  )
}

export default Newsletter
