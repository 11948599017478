import daisyui from 'daisyui'
import 'tailwindcss/tailwind.css'
import Icon from '../DecoHeader/DecoIcon/Icon'

export default function BackToTop({ content }: { content?: string }) {
  return (
    <>
      {content && (
        <div className="w-full flex items-center justify-center">
          <a href="#top" className="btn btn-accent">
            {content} <Icon id="ChevronUp" width={24} height={24} />
          </a>
        </div>
      )}
    </>
  )
}
