import { useEffect, useState } from 'react'
import 'tailwindcss/tailwind.css'
import { SocialItem } from '../DecoFooter/Social'
import Alert, { IAlert } from './Alert'
import Drawers from './Drawers'
import { FastLink, SiteNavigationElement } from './Menu'
import Navbar from './Navbar'

export interface Props {
  alerts: IAlert[]

  /** @title Search Bar */
  searchbar?: any | undefined

  /**
   * @title Navigation items
   * @description Navigation items used both on mobile and desktop menus
   */
  navItems?: SiteNavigationElement[]
  /**
   * @description Links used on mobile bottom menu
   */
  fastLinks?: FastLink[]
  /**
   * @description Links used on mobile bottom menu
   */
  socialLinks?: SocialItem[]

  /** @title Cart Settings */
  cart?: any | any[] | undefined
}

function DecoHeader({
  alerts,
  searchbar,
  navItems,
  fastLinks,
  device,
  imageBaseUrl,
  cart,
  socialLinks,
}: any) {
  const items = navItems ?? []
  const [isMobile, setIsMobile] = useState('mobile')
  const [displayMenu, setDisplayMenu] = useState(false)

  useEffect(() => {
    checkResize()
    closeMenu()
    window.addEventListener('resize', checkResize)
  },[])

  const checkResize = () => {
    setIsMobile(window.innerWidth < 768 ? 'mobile' : 'desktop')
  }

  const toggleMenu = () => setDisplayMenu(!displayMenu)
  const closeMenu = () => setDisplayMenu(false)

  return (
    <>
      <header className="h-[120px]" data-header>
        <div className="fixed top-0 left-0 w-full z-30 ">
          <Alert alerts={alerts} />
          <div className="border-t border-b sm:border-b-0 border-base-300 bg-base-100 w-full relative">
            <Navbar
              items={items}
              searchbar={searchbar && { ...searchbar }}
              device={isMobile}
              setDisplayMenu={toggleMenu}
              displayMenu={displayMenu}
            />
          </div>
        </div>
      </header>
      <Drawers
        menu={{ items, fastLinks, socialItems: socialLinks }}
        searchbar={searchbar}
        imageBaseUrl={imageBaseUrl}
        cart={cart}
        displayMenu={displayMenu}
        setDisplayMenu={closeMenu}
      />
    </>
  )
}


export default DecoHeader
