export type AvailableIcons =
  | 'American'
  | 'ArrowsPointingOut'
  | 'Bars3'
  | 'ChevronLeft'
  | 'ChevronRight'
  | 'ChevronUp'
  | 'ChevronDown'
  | 'ArrowRight'
  | 'CreditCard'
  | 'Deco'
  | 'Diners'
  | 'Discord'
  | 'YouTube'
  | 'Discount'
  | 'Elo'
  | 'Facebook'
  | 'FilterList'
  | 'Heart'
  | 'Instagram'
  | 'Linkedin'
  | 'Minus'
  | 'MapPin'
  | 'MagnifyingGlass'
  | 'Mastercard'
  | 'Message'
  | 'Phone'
  | 'PagaLeve'
  | 'Pix'
  | 'Plus'
  | 'QuestionMarkCircle'
  | 'Return'
  | 'Ruler'
  | 'ShoppingCart'
  | 'Star'
  | 'Tiktok'
  | 'Trash'
  | 'Truck'
  | 'Twitter'
  | 'User'
  | 'Visa'
  | 'WhatsApp'
  | 'XMark'
  | 'PlusSign'
  | 'MinusSign'
  | 'Zoom'
  | 'MagnifyingGlassFound'
  | 'ArrowUp'
  | 'Cloud'
  | 'Zoom'
  | 'Check'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  /**
   * Symbol id from element to render. Take a look at `/static/icons.svg`.
   *
   * Example: <Icon id="Bell" />
   */
  id: AvailableIcons
  size?: number
}

function Icon({
  id,
  strokeWidth = 16,
  size,
  width,
  height,
  ...otherProps
}: Props) {
  return (
    <svg
      {...otherProps}
      width={width ?? size}
      height={height ?? size}
      strokeWidth={strokeWidth}
    >
      <use href={`/sprites.svg#${id}`} />
    </svg>
  )
}

export default Icon
