import 'tailwindcss/tailwind.css'
import CartButton from './Buttons/Cart'
import MenuButton from './Buttons/Menu'
import SearchButton from './Buttons/Search'
import User from './Buttons/User'
import { Logo } from './DecoLogo'
import { SiteNavigationElement } from './Menu'
import NavItem from './NavItem'

function Navbar({
  items,
  searchbar,
  device,
  setDisplayMenu,
  displayMenu
}: {
  items: SiteNavigationElement[];
  device: string;
  searchbar?: any;
  setDisplayMenu: Function,
  displayMenu: boolean
}) {
  if (device !== 'desktop') {
    return (
      <div className="flex flex-row justify-between items-center w-full px-4 py-5 gap-2 relative ">
        <MenuButton displayMenu={displayMenu} setDisplayMenu={setDisplayMenu}/>
        <a
          href="/"
          className="flex-grow inline-flex items-center"
          aria-label="Store logo"
        >
          <Logo isMobile />
        </a>
        <div className="flex gap-6">
          <User />
          <CartButton />
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="container flex flex-row justify-between items-center w-full pl-2 pr-6 relative">
        <div className="flex-none w-40">
          <a href="/" aria-label="Store logo" className="block py-6 w-[160px]">
            <Logo />
          </a>
        </div>
        <div className="flex-auto flex justify-start self-stretch">
          {items.map((item, index) => <NavItem item={item} key={item.label+index}/>)}
        </div>
        <div className="flex-none flex items-center justify-end gap-10">
          <User />
          <CartButton />
        </div>
      </div>
    </>
  )
}

export default Navbar
