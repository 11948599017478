import React from 'react'
import 'tailwindcss/tailwind.css'
function Dot({
  index,
  children,
}: {
  index: number;
  children: React.ReactNode;
}) {
  return (
    <button
      data-dot={index}
      aria-label={`go to slider item ${index}`}
      className="focus:outline-none group"
    >
      {children}
    </button>
  )
}

function Slider(props: JSX.IntrinsicElements['ul']) {
  return <ul data-slider {...props} />
}

function Item({
  index,
  ...props
}: JSX.IntrinsicElements['li'] & { index: number }) {
  return <li data-slider-item={index} {...props} />
}

function NextButton(props: JSX.IntrinsicElements['button']) {
  return <button data-slide="next" aria-label="Next item" {...props} />
}

function PrevButton(props: JSX.IntrinsicElements['button']) {
  return <button data-slide="prev" aria-label="Previous item" {...props} />
}

Slider.Dot = Dot
Slider.Item = Item
Slider.NextButton = NextButton
Slider.PrevButton = PrevButton

export default Slider
