export const replaceDecoUrl = (url?: string): string => {
  if(!url) return '#'

  if(url.includes('https://')) {
    const baseUrl = url.split('https://')[1]
    return baseUrl.includes('persono.com.br') ? baseUrl.replace('persono.com.br', '') : url
  }
  
  if(url === '/blog') return '/'

  const decoBaseUrl = process.env.REACT_APP_ENV === 'production' 
    ? 'https://shop.persono.com.br' : 'https://dev.shop.persono.com.br'
 
  return decoBaseUrl + url
}