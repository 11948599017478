import 'tailwindcss/tailwind.css'
import Icon from '../DecoHeader/DecoIcon/Icon'

export interface SocialItem {
  label:
    | 'Discord'
    | 'Facebook'
    | 'Instagram'
    | 'Linkedin'
    | 'Tiktok'
    | 'YouTube'
    | 'Twitter'
  link: string
}

export default function Social(
  { content, vertical = false }: {
    content?: { title?: string; items?: SocialItem[] };
    vertical?: boolean;
  },
) {
  return (
    <>
      {content && content.items && content.items.length > 0 && (
        <div className="flex flex-col gap-4">
          {content.title && <h3 className="text-lg">{content.title}</h3>}
          <ul
            className={`flex gap-4 ${
              vertical ? 'lg:flex-col lg:items-start' : 'flex-wrap items-center'
            }`}
          >
            {content.items.map(item => {
              return (
                <li key={item.label}>
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={`${item.label} Logo`}
                    className="flex gap-2 items-center"
                  >
                    <span className="block rounded-full">
                      <Icon size={24} id={item.label} />
                    </span>
                    {vertical && (
                      <div className="text-sm hidden lg:block">{item.label}</div>
                    )}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
      )}
    </>
  )
}
