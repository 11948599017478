import { useId } from 'react'
import 'tailwindcss/tailwind.css'
import Slider from './DecoSlider/Slider'
import SliderJS from './DecoSlider/SliderJs'

export interface Icon {
  icon: string
  /** @description It will apply on width and height */
  size: number
}

export interface IAlert {
  label: string
  link?: string
  beforeIcon?: Icon
  afterIcon?: Icon
  layout: 'primary' | 'secondary' | 'accent'
}

export interface Props {
  alerts: IAlert[]
  /**
   * @title Autoplay interval
   * @description time (in seconds) to start the carousel autoplay. Defaults to 5
   */
  interval?: number
}

const LAYOUT = {
  primary: 'bg-primary text-primary-content',
  secondary: 'bg-secondary text-secondary-content',
  accent: 'bg-accent text-accent-content',
} as const

function Alert({ alerts = [], interval = 5 }: Props) {
  const id = useId()

  return (
    <div
      id={id}
      data-top-bar
      className="transition-all overflow-hidden duration-300"
    >
      <Slider className="carousel carousel-center w-screen">
        {alerts.map((alert, index) => (
          <Slider.Item
            index={index}
            className={`carousel-item ${LAYOUT[alert.layout]}`}
            key={index + alert.label}
          >
            {alert.link
              ? (
                <a
                  href={alert.link}
                  className={`text-sm flex justify-center items-center w-screen gap-2 lg:py-2.5 py-2`}
                >
                  {alert.beforeIcon
                    ? (
                      <img
                        src={alert.beforeIcon.icon}
                        width={alert.beforeIcon.size}
                        height={alert.beforeIcon.size}
                        // preload
                        // fetchPriority="high"
                        aria-hidden="true"
                      />
                    )
                    : null}
                  {alert.label}
                  {alert.afterIcon
                    ? (
                      <img
                        src={alert.afterIcon.icon}
                        width={alert.afterIcon.size}
                        height={alert.afterIcon.size}
                        // preload
                        // fetchPriority="high"
                        aria-hidden="true"
                      />
                    )
                    : null}
                </a>
              )
              : (
                <div
                  className={`text-sm flex justify-center items-center w-screen gap-2 lg:py-2.5 py-2`}
                >
                  {alert.beforeIcon
                    ? (
                      <img
                        src={alert.beforeIcon.icon}
                        width={alert.beforeIcon.size}
                        height={alert.beforeIcon.size}
                        // preload
                        // fetchPriority="high"
                        aria-hidden="true"
                      />
                    )
                    : null}
                  {alert.label}
                  {alert.afterIcon
                    ? (
                      <img
                        src={alert.afterIcon.icon}
                        width={alert.afterIcon.size}
                        height={alert.afterIcon.size}
                        // preload
                        // fetchPriority="high"
                        aria-hidden="true"
                      />
                    )
                    : null}
                </div>
              )}
          </Slider.Item>
        ))}
      </Slider>

      <SliderJS rootId={id} interval={interval && interval * 1e3} />
    </div>
  )
}

export default Alert
