import 'tailwindcss/tailwind.css'
import { DecoFooterProps } from '../../../services/interfaces/DecoComponentsDtos'
import LeadStore from '../../stores/LeadStore/LeadStore'
import BackToTop from './BackToTop'
import ColorClasses from './ColorClasses'
import Divider from './Divider'
import FooterItems from './FooterItems'
import Logo from './Logo'
import Newsletter from './Newsletter'
import PaymentMethods from './PaymentMethods'
import Social, { SocialItem } from './Social'

export interface Layout {
  backgroundColor?:
    | 'Primary'
    | 'Secondary'
    | 'Accent'
    | 'Base 100'
    | 'Base 100 inverted'
  hide?: {
    logo?: boolean;
    newsletter?: boolean;
    sectionLinks?: boolean;
    socialLinks?: boolean;
    paymentMethods?: boolean;
    backToTheTop?: boolean;
  }
}

const store = new LeadStore()

function DecoFooter({
  logo,
  newsletter = {
    title: 'Newsletter',
    description: '',
    form: { placeholder: '', buttonText: '', helpText: '' },
  },
  sections = [{
    'label': 'Sobre',
    'items': [
      {
        'href': '/quem-somos',
        'label': 'Quem somos',
      },
      {
        'href': '/termos-de-uso',
        'label': 'Termos de uso',
      },
      {
        'href': '/trabalhe-conosco',
        'label': 'Trabalhe conosco',
      },
    ],
  }, {
    'label': 'Atendimento',
    'items': [
      {
        'href': '/centraldeatendimento',
        'label': 'Central de atendimento',
      },
      {
        'href': '/whatsapp',
        'label': 'Fale conosco pelo WhatsApp',
      },
      {
        'href': '/trocaedevolucao',
        'label': 'Troca e devolução',
      },
    ],
  }],
  social = {
    title: 'Redes sociais',
    items: [{ label: 'Instagram', link: '/' }, { label: 'Tiktok', link: '/' }],
  },
  payments = {
    title: 'Formas de pagamento',
    items: [{ label: 'Mastercard' }, { label: 'Visa' }, { label: 'Pix' }],
  },
  backToTheTop,
  layout = {
    backgroundColor: 'Primary',
    hide: {
      logo: false,
      newsletter: false,
      sectionLinks: false,
      socialLinks: false,
      paymentMethods: false,
      backToTheTop: false,
    },
  },
  copyright,
}: any | DecoFooterProps) {
  const _logo = layout?.hide?.logo 
    ? <></> 
    : <Logo logo={logo} />

  const _sectionLinks = layout?.hide?.sectionLinks
    ? <></>
    : <FooterItems
      sections={sections}
      justify={false}
    />

  const _social = layout?.hide?.socialLinks
    ? <></>
    : <Social content={social} vertical={false} />

  const _payments = layout?.hide?.paymentMethods
    ? <></>
    : <PaymentMethods content={payments} />
    
  const _backToTop = layout?.hide?.backToTheTop
    ? <></>
    : <BackToTop content={backToTheTop?.text} />

  return (
    <footer
      className={`w-full flex flex-col md:pt-12 pt-6 ${ColorClasses(layout)}`}
    >
      <div className="lg:container mx-6 lg:mx-auto">
        <div className="flex flex-col md:gap-12 gap-6">
          {layout?.hide?.newsletter ? <></> : (
            <>
              <Newsletter
                content={newsletter}
                layout={{
                  tiled: true,
                }}
                store={store}
              />
              <Divider />
            </>
          )}
          <div className="flex flex-col lg:flex-row lg:gap-20 md:gap-10 gap-4 lg:justify-start">
            {_sectionLinks}
            {_payments}
          </div>
          <Divider />
        </div>
        <div className="flex flex-row justify-between items-center md:py-8 pt-5 pb-4">
          {_logo}
          {_social}
        </div>
        {copyright
          ? (
            <p className="mt-6 mb-8 text-xs lg:text-sm">
              {copyright}
            </p>
          )
          : null}
      </div>
      {_backToTop}
    </footer>
  )
}

export type { SocialItem }

export default DecoFooter
