import 'tailwindcss/tailwind.css'
import { replaceDecoUrl } from '../../../../utils/replaceDecoUrl'
import Icon from '../DecoIcon/Icon'

const User = () => {
  return (
    <a
      className="btn btn-circle hover:text-primary btn-sm btn-ghost flex items-center justify-center"
      href={replaceDecoUrl('/login')}
      aria-label="área logada"
    >
      <Icon id="User" size={24} strokeWidth={0.4} />
    </a>
  )
}

export default User
