export const Logo = ({
  isMobile,
  onlySymbol,
  color = 'black',
}: {
  isMobile?: boolean;
  onlySymbol?: boolean;
  color?: string;
}) =>
  onlySymbol
    ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 30 30"
        fill="none"
      >
        <g clipPath="url(#clip0)">
          <path
            d="M14.2156 3.12827C20.4882 3.12827 25.588 8.23165 25.588 14.5007C25.588 19.8884 21.8244 24.4125 16.7886 25.5782C15.5376 18.641 10.0788 13.1502 3.15941 11.8495C4.36062 6.8563 8.87406 3.12827 14.2191 3.12827M14.2191 0.285156C6.397 0.285156 0.0533084 6.59687 0 14.4012C7.85054 14.4012 14.2156 20.7662 14.2156 28.6168C14.2156 28.6523 14.2156 28.6843 14.2156 28.7163C22.0661 28.7163 28.4311 22.3513 28.4311 14.5007C28.4311 6.65017 22.0661 0.285156 14.2156 0.285156Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect
              width="100%"
              height="100%"
              fill="white"
              transform="translate(0 0.285156)"
            />
          </clipPath>
        </defs>
      </svg>
    )
    : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        {...(isMobile
          ? {
            width: '77',
            height: '21',
            viewBox: '34 6 76 21',
          }
          : {
            width: '110',
            height: '29',
            viewBox: '0 0 110 29',
          })}
        fill="none"
      >
        <g clipPath="url(#clip0_726_2829)">
          <path
            d="M37.556 11.6793C36.4721 11.6793 35.4521 11.754 34.5387 11.8606C33.9985 11.9246 33.5898 12.3759 33.5898 12.9196V25.8949H36.433V21.5165C36.7386 21.5378 37.2752 21.5627 37.6839 21.5627C41.0246 21.5627 43.8535 20.1269 43.8535 16.6441C43.8535 13.1613 41.4297 11.6758 37.556 11.6758V11.6793ZM37.4814 19.0217C37.0975 19.0217 36.6391 18.9968 36.4365 18.9755V14.3838C36.7421 14.3376 37.2255 14.3128 37.5347 14.3128C39.4218 14.3128 40.6194 15.0662 40.6194 16.6441C40.6194 18.222 39.4467 19.0217 37.4849 19.0217H37.4814Z"
            fill={color}
          />
          <path
            d="M50.0216 14.5215C48.4011 14.5215 47.1003 15.0475 46.0946 16.0959C45.0675 17.1656 44.5664 18.5125 44.5664 20.1544C44.5664 23.5057 46.9617 25.8975 50.5902 25.8975C52.3245 25.8975 53.7177 25.6238 54.7661 25.1014L54.1193 22.6812C53.0673 23.1183 51.891 23.3458 50.5902 23.3458C48.9697 23.3458 47.8289 22.3649 47.669 21.0677H55.1072C55.1534 20.7017 55.1748 20.3605 55.1748 20.0656C55.1748 16.7142 53.3943 14.5286 50.0181 14.5286L50.0216 14.5215ZM47.6725 19.3086C47.8324 18.0078 48.7209 17.0519 50.0678 17.0519C51.4148 17.0519 52.0758 17.8053 52.1895 19.3086H47.6725Z"
            fill={color}
          />
          <path
            d="M62.9915 14.685C62.6254 14.5677 62.2594 14.5215 61.872 14.5215C60.3403 14.5215 58.8121 14.8698 57.2342 15.5805C56.8503 15.7547 56.6016 16.135 56.6016 16.5579V25.8975H59.4944V18.2744C60.3189 17.4925 61.5237 17.3397 62.2842 17.3326L62.9915 14.6885V14.685Z"
            fill={color}
          />
          <path
            d="M70.1673 19.2304L68.1878 18.8217C67.246 18.6404 66.784 18.3881 66.784 17.9119C66.784 17.3646 67.3597 16.9559 68.3939 16.9559C69.6129 16.9559 70.7786 17.2402 71.8732 17.8088L72.5093 15.4313C71.3365 14.8165 69.9327 14.5215 68.2766 14.5215C65.5863 14.5215 63.6992 15.7938 63.6992 17.9545C63.6992 19.6142 64.5948 20.6377 66.4144 21.0251L68.3015 21.4125C69.4743 21.6612 70.0713 22.0024 70.0713 22.4822C70.0713 23.0046 69.4281 23.4844 68.3015 23.4844C66.9333 23.4844 65.6752 23.1859 64.5131 22.5746L63.8627 25.0055C64.968 25.5528 66.8764 25.8939 68.2553 25.8939C71.3827 25.8939 73.1774 24.5968 73.1774 22.436C73.1774 20.5027 72.1895 19.6604 70.1637 19.2304H70.1673Z"
            fill={color}
          />
          <path
            d="M104.311 14.5215C101.17 14.5215 98.625 17.0661 98.625 20.2077C98.625 23.3493 101.17 25.8939 104.311 25.8939C107.453 25.8939 109.997 23.3493 109.997 20.2077C109.997 17.0661 107.453 14.5215 104.311 14.5215ZM104.311 23.4062C102.74 23.4062 101.468 21.974 101.468 20.2077C101.468 18.4414 102.74 17.0092 104.311 17.0092C105.882 17.0092 107.154 18.4414 107.154 20.2077C107.154 21.974 105.882 23.4062 104.311 23.4062Z"
            fill={color}
          />
          <path
            d="M79.5729 14.5215C76.4313 14.5215 73.8867 17.0661 73.8867 20.2077C73.8867 23.3493 76.4313 25.8939 79.5729 25.8939C82.7146 25.8939 85.2592 23.3493 85.2592 20.2077C85.2592 17.0661 82.7146 14.5215 79.5729 14.5215ZM79.5729 23.4062C78.0021 23.4062 76.7298 21.974 76.7298 20.2077C76.7298 18.4414 78.0021 17.0092 79.5729 17.0092C81.1438 17.0092 82.4161 18.4414 82.4161 20.2077C82.4161 21.974 81.1438 23.4062 79.5729 23.4062Z"
            fill={color}
          />
          <path
            d="M92.0461 14.5215C90.5499 14.5215 88.9826 14.8698 87.3265 15.5841C86.9356 15.7547 86.6797 16.1385 86.6797 16.5685V25.8939H89.8462V17.9688C90.621 17.5707 91.3921 17.3824 92.1669 17.3824C93.2935 17.3824 94.0433 18.0398 94.0433 18.9781V25.8939H97.2063V19.0953C97.2063 16.0248 95.209 14.5215 92.0496 14.5215H92.0461Z"
            fill={color}
          />
          {!isMobile
            ? (
              <path
                d="M14.2156 3.12827C20.4882 3.12827 25.588 8.23165 25.588 14.5007C25.588 19.8884 21.8244 24.4125 16.7886 25.5782C15.5376 18.641 10.0788 13.1502 3.15941 11.8495C4.36062 6.8563 8.87406 3.12827 14.2191 3.12827M14.2191 0.285156C6.397 0.285156 0.0533084 6.59687 0 14.4012C7.85054 14.4012 14.2156 20.7662 14.2156 28.6168C14.2156 28.6523 14.2156 28.6843 14.2156 28.7163C22.0661 28.7163 28.4311 22.3513 28.4311 14.5007C28.4311 6.65017 22.0661 0.285156 14.2156 0.285156H14.2191Z"
                fill={color}
              />
            )
            : null}
        </g>
        <defs>
          <clipPath id="clip0_726_2829">
            <rect
              width="110"
              height="28.4311"
              fill="white"
              transform="translate(0 0.285156)"
            />
          </clipPath>
        </defs>
      </svg>
    )
