import 'tailwindcss/tailwind.css'
import CartButtonn from './Cart/common'

function CartButton() {
  return (
    <CartButtonn
      currency="BRL"
      loading={false}
      total={0}
      items={[]}
    />
  )
}

export default CartButton
