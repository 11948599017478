import { forwardRef } from 'react'
import 'tailwindcss/tailwind.css'

export type Props =
  & Omit<JSX.IntrinsicElements['button'], 'loading'>
  & {
    loading?: boolean;
  }

const Button = forwardRef<HTMLButtonElement, Props>(({
  type = 'button',
  className: _class = '',
  loading,
  disabled,
  children,
  ...props
}, ref) => (
  <button
    {...props}
    className={`btn no-animation ${_class}`}
    disabled={disabled || loading}
    type={type}
    ref={ref}
  >
    {loading ? <span className="loading loading-spinner" /> : children}
  </button>
))

export default Button
