import daisyui from 'daisyui'
import 'tailwindcss/tailwind.css'
import { replaceDecoUrl } from '../../../utils/replaceDecoUrl'

export type Item = {
  label: string;
  href: string;
}

export type Section = {
  label: string;
  items: Item[];
}

export default function FooterItems(
  { sections, justify = false }: { sections: Section[]; justify: boolean },
) {
  return (
    <>
      {sections.length > 0 && (
        <>
          {/* Tablet and Desktop view */}
          <ul
            className={`hidden md:flex flex-row lg:gap-20 gap-10 ${
              justify && 'lg:justify-between'
            }`}
          >
            {sections.map(section => (
              <li key={section.label + section.items.length}>
                <div className="flex flex-col gap-2 text-sm">
                  <span className="font-bold">
                    {section.label}
                  </span>
                  <ul className={`flex flex-col gap-2 flex-wrap`}>
                    {section.items?.map((item, index) => (
                      <li key={item.label + index}>
                        <a href={replaceDecoUrl(item.href)} className="block link link-hover">
                          {item.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
            ))}
          </ul>

          {/* Mobile view */}
          <ul className="flex flex-col md:hidden gap-2">
            {sections.map(section => (
              <li key={section.label + section.items.length + `mobile`}>
                <div className="collapse collapse-arrow text-sm">
                  <input
                    type="checkbox"
                    className="min-h-[0] "
                    aria-label={section.label + ' footer Item'}
                  />
                  <div className="collapse-title font-bold min-h-[0] !p-0 flex gap-2">
                    <span style={{marginTop: '20px'}}>{section.label}</span>
                  </div>
                  <div className="collapse-content">
                    <ul
                      className={`flex flex-col gap-1 pl-0 pt-2`}
                    >
                      {section.items?.map((item, index) => (
                        <li key={item.label+index}>
                          <a
                            href={replaceDecoUrl(item.href)}
                            className="block link link-hover"
                          >
                            {item.label}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </>
      )}
    </>
  )
}
