import { Suspense, useEffect, useState } from 'react'
import 'tailwindcss/tailwind.css'
import Drawer from './DecoDrawer/Drawer'
import Menu from './Menu'

export interface Props {
  menu: any | null | undefined,
  searchbar?: any | null | undefined,
  cart?: any | null | undefined,
  imageBaseUrl?: string | any | null | undefined,
  displayMenu?: boolean
  setDisplayMenu?: Function
}

const Aside = ({ children }: { children: React.ReactNode | any }) => (
  <div className="bg-base-100 flex flex-col items-stretch justify-items-stretch h-full divide w-full sm:max-w-xl">
    <Suspense
      fallback={
        <div className="w-screen flex items-center justify-center">
          <span className="loading loading-ring" />
        </div>
      }
    >
      {children}
    </Suspense>
  </div>
)

const setup = () => {
  const header = document.querySelector<HTMLElement>('[data-header]')
  const topBar = document.querySelector<HTMLElement>('[data-top-bar]')
  const drawer = document.querySelector<HTMLElement>(
    '.menu-drawer > .drawer-side',
  )

  if (!header || !topBar || !drawer) return

  const handleScroll = () => {
    if (globalThis.window.scrollY > 40) {
      drawer.style.height = 'calc(100% - 80px)'
      topBar.style.maxHeight = '0px'
      return
    }
    drawer.style.height = 'calc(100% - 120px)'
    topBar.style.maxHeight = '44px'
  }

  addEventListener('scroll', handleScroll)
  handleScroll()

  return () => {
    removeEventListener('scroll', handleScroll)
  }
}

function Drawers(
  { menu, searchbar, cart, imageBaseUrl, displayMenu, setDisplayMenu }: Props,
) {
  const [showDrawer, setShowDrawer] = useState(false)

  useEffect(() => {
    setup()
    setTimeout(() => {
      setShowDrawer(true)
    }, 100)

    setDisplayMenu()

    return () => {
      setShowDrawer(false)
    }
  }, [])

  if(!showDrawer) return <></>

  return (
    <>
      <Drawer // left drawer
        open={displayMenu}
        onClose={() => {
          setDisplayMenu()
        }}
        class="menu-drawer"
        showHeader
        aside={
          <Aside>
            <Menu {...menu} />
          </Aside>
        }
      />
    </>
  )
}

export default Drawers
