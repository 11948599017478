import 'tailwindcss/tailwind.css'
import { replaceDecoUrl } from '../../../utils/replaceDecoUrl'
import Icon from './DecoIcon/Icon'
import { SiteNavigationElement } from './Menu'

function NavItem({ item }: { item: SiteNavigationElement }) {
  const { url, label, children } = item
  const image = item?.image

  return (
    <li className="group flex items-center px-4">
      <a href={replaceDecoUrl(url)} className="pt-3 flex items-center gap-2">
        <span className="group-hover:text-primary uppercase">
          {label}
        </span>
        {children?.length
          ? (
            <>
              <Icon
                id="ChevronDown"
                className="group-hover:hidden block"
                size={14}
              />
              <Icon id="ChevronUp" className="group-hover:block hidden" size={14} />
            </>
          )
          : null}
      </a>

      {!!children?.length &&
        (
          <>
            <div className="-z-10 border-t border-base-200 left-1/2 top-full -translate-x-1/2 w-[85%] absolute hidden hover:flex group-hover:flex bg-base-100 items-stretch self-stretch justify-between gap-6 py-10 px-20 rounded-b-[20px] shadow-[0px_1px_5px_0px_rgba(0,0,0,0.14)]">
              <ul className="flex flex-col justify-start gap-2 py-2 relative">
                {children.map(node => (
                  <li className="group/item leading-8 hover:text-primary" key={node.label}>
                    <a className="pr-10" href={replaceDecoUrl(node.url)}>
                      {node.label}
                    </a>
                    {!!node.children?.length && (
                      <ul className="py-2 pl-8 text-gray-600 h-full absolute left-full top-0 group-hover/item:flex flex-col justify-start gap-2 hidden border-l border-gray-100">
                        {node.children.map(childNode => (
                          <li className="leading-2" key={childNode.label}>
                            <a className="hover:text-black" href={replaceDecoUrl(childNode.url)}>
                              {childNode.label}
                            </a>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
              {image?.url && (
                <img
                  className="rounded-xl"
                  src={image.url}
                  alt={image.alt}
                  width={300}
                  height={349}
                  loading="lazy"
                />
              )}
            </div>
            <div className="group-hover:block hidden absolute w-[200%] h-screen bg-black opacity-[15%] top-0 left-0 -translate-x-[7%] -z-20 pointer-events-none" />
          </>
        )}
    </li>
  )
}

export default NavItem
